import React from "react";
import { getStripeLink, postXMonthPurchase } from "../../screens/profile/api";
import Link from "@mui/material/Link";
import { Button, CircularProgress } from "@material-ui/core";

export const IndividualSubscriptionInfo = ({
  parent,
  modalControl,
  months,
  data,
  sale,
  bonuses,
  hasSubscribed,
  webSignup,
}) => {
  const {
    cadence,
    monthlyRate,
    saleMonthlyRate,
    freeDuration,
    bgColor,
    totalCost,
    saleTotalCost,
    buttonColor,
    buttonOutline,
    textColor,
    buttonText,
    borderColor,
  } = data;
  const bonusesCopy = { ...bonuses };
  if (!hasSubscribed) {
    bonusesCopy[0] = [`2 week free trial included!`, ...bonuses[0]];
  }

  const styles = {
    optionContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "0 5px",
    },
    rowStyles: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    cadence: {
      fontWeight: "bold",
      fontSize: "large",
      paddingTop: "12px",
    },
    totalCost: {
      fontSize: "x-large",
    },
    monthlyRate: {
      fontSize: "x-large",
    },
    savings: {
      fontSize: "x-large",
      fontWeight: "bold",
    },
    bonus: {
      fontSize: "large",
      height: "100%",
      justifyContent: months > 0 ? "left" : "center",
      alignItems: "start",
      borderWidth: "1px 0 0 0",
    },
    infoContainer: {
      borderWidth: 3,
      borderStyle: "solid",
      borderColor: borderColor,
      padding: 20,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: bgColor,
      color: "black",
    },
    startColumn: {
      fontWeight: "bold",
    },
    listContainer: {
      margin: "20px 0",
      paddingLeft: "35px",
      paddingRight: "15px",
    },
    listEntry: {
      padding: "5px 0",
      textAlign: "left",
      paddingBottom: "5px",
      fontWeight: "500",
      fontSize: "medium",
    },
    divider: {
      height: 3,
      borderRadius: "5px",
      width: "50%",
      backgroundColor: "#002141",
      margin: "5px 0",
    },
    priceDisplay: {
      display: "flex",
    },
    sale: {
      color: "red",
    },
    slashThrough: {
      textDecorationLine: "line-through",
    },
    dollarPrice: {
      fontSize: "xx-large",
      fontWeight: "700",
    },
    centsPrice: {
      paddingTop: 6,
      fontSize: "small",
    },
    perMonth: {
      fontSize: "large",
      fontWeight: "400",
    },
    totalDollarPrice: {
      fontSize: "medium",
      fontWeight: "700",
    },
    totalCentsPrice: {
      paddingTop: 1,
      fontSize: "x-small",
    },
    totalPerMonth: {
      fontSize: "small",
      fontWeight: "400",
      paddingLeft: 3,
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    buttonStyle: {
      width: "40%",
      backgroundColor: buttonColor,
      padding: "10px 0",
      fontWeight: "bold",
      fontSize: "medium",
      color: textColor,
      borderRadius: 25,
      borderColor: buttonOutline,
      borderWidth: 2,
      borderStyle: "solid",
      cursor: "pointer",
    },
    singleMonth: {
      opacity: 0.3,
    },
    fullYear: {
      color: "#0089ff",
    },
  };

  let bonusStyle = { ...styles.listEntry };
  if (parseInt(months) === 1) {
    bonusStyle = { ...styles.listEntry, ...styles.singleMonth };
  } else {
    bonusStyle = { ...styles.listEntry, ...styles.fullYear };
  }

  let basePriceDisplayStyle = { ...styles.priceDisplay };
  if (!!sale) {
    basePriceDisplayStyle = { ...styles.priceDisplay, ...styles.slashThrough };
  }

  return (
    <div style={styles.optionContainer}>
      <div style={styles.infoContainer}>
        <div style={{ ...styles.rowStyles, ...styles.cadence }}>{cadence}</div>
        <div style={{ ...styles.rowStyles }}>
          <div style={basePriceDisplayStyle}>
            <div style={{ ...styles.priceDisplay, ...styles.dollarPrice }}>
              ${monthlyRate[0]}
            </div>
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ ...styles.priceDisplay, ...styles.centsPrice }}>
                {monthlyRate[1]}
              </div>
            </div>
          </div>
          {!!sale ? (
            <div style={{ ...styles.priceDisplay, ...styles.sale }}>
              <div style={{ ...styles.priceDisplay, ...styles.dollarPrice }}>
                ${saleMonthlyRate[0]}
              </div>
              <div style={{ display: "flex", height: "100%" }}>
                <div style={{ ...styles.priceDisplay, ...styles.centsPrice }}>
                  {saleMonthlyRate[1]}
                </div>
              </div>
            </div>
          ) : null}
          <div style={{ ...styles.priceDisplay, ...styles.perMonth }}>
            / month
          </div>
        </div>
        <div style={styles.divider} />
        <div style={{ ...styles.rowStyles }}>
          <div style={basePriceDisplayStyle}>
            <div style={{ ...styles.priceDisplay, ...styles.totalDollarPrice }}>
              {totalCost[0]}
            </div>
            <div style={{ display: "flex", height: "100%" }}>
              <div
                style={{ ...styles.priceDisplay, ...styles.totalCentsPrice }}
              >
                {totalCost[1]}
              </div>
            </div>
          </div>
          {!!sale ? (
            <div style={{ ...styles.priceDisplay, ...styles.sale }}>
              <div
                style={{ ...styles.priceDisplay, ...styles.totalDollarPrice }}
              >
                {saleTotalCost[0]}
              </div>
              <div style={{ display: "flex", height: "100%" }}>
                <div
                  style={{ ...styles.priceDisplay, ...styles.totalCentsPrice }}
                >
                  {saleTotalCost[1]}
                </div>
              </div>
            </div>
          ) : null}
          <div style={{ ...styles.priceDisplay, ...styles.totalPerMonth }}>
            {totalCost[2]}
          </div>
        </div>
        <div style={{ ...styles.rowStyles, ...styles.bonus }}>
          <ul style={{ ...styles.listContainer }}>
            {bonusesCopy[0].map((entry) => {
              return <li style={{ ...styles.listEntry }}>{entry}</li>;
            })}
            {bonusesCopy[1].map((entry) => {
              return <li style={bonusStyle}>{entry}</li>;
            })}
          </ul>
        </div>
        <div style={styles.buttonContainer}>
          <div
            style={styles.buttonStyle}
            onClick={() => {
              const monthType =
                parseInt(months) === 1
                  ? "1MonthPurchase"
                  : parseInt(months) === 12
                  ? "12MonthPurchase"
                  : "ERROR";
              parent.setState({
                dialogOpen: true,
                dialogType: monthType,
              });
              modalControl.setState({
                modalOpen: true,
                monthType,
              });
            }}
          >
            {buttonText}
          </div>
        </div>
      </div>
    </div>
  );
};
