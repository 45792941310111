import React from "react";
import { styles } from "../../screens/onboarding/styles";
import {
  Button,
  withStyles,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { applyPromo } from "./utils";
import { getStripeLink, postXMonthPurchase } from "../../screens/profile/api";
import { IndividualSubscriptionInfo } from "../SubscriptionOptions/IndividualSubscriptionOption";
import Link from "@mui/material/Link";

const saleData = {
  percent: 0,
  reason: "HOLIDAY",
};

const getButtonText = (parent) => {
  let { monthsCredit, daysCredit, priceNow } = parent.state;
  let topText, botText;

  priceNow = priceNow ? parseInt(priceNow / 100) : priceNow;

  topText = "CLICK TO SUBSCRIBE";
  if (daysCredit) {
    botText = `${daysCredit} days for $${priceNow}`;
  } else if (monthsCredit) {
    botText = `${
      monthsCredit > 1 ? monthsCredit + " months" : "First month"
    } for $${priceNow}`;
  } else {
    botText = "$40 per month";
  }

  return { topText, botText };
};

const _RenderButton = (parent, classes) => {
  let { topText, botText } = getButtonText(parent);

  if (!parent.state.payment_method) {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div
          id={"pay_submit_button"}
          name={"pay_submit_button"}
          className={classes.payButton}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 15,
          }}
          onClick={() => getStripeLink(parent, 0, saleData["percent"])}
        >
          {parent.state.loading ? (
            <CircularProgress
              size={50}
              classes={{ circle: classes.circularProgressPrimaryColor }}
              color="primary"
            />
          ) : (
            <div>
              <div className={classes.btnText}>{topText}</div>
              <div className={classes.btnTextSmall}>{botText}</div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    if (parent.state.payment_method === "APP_STORE") {
      return (
        <div className={classes.appStoreNoticeContainer}>
          Apple Pay subscriptions are managed in iCloud settings, under
          "Subscriptions"
        </div>
      );
    } else if (parent.state.payment_method === "PLAY_STORE") {
      return (
        <div className={classes.appStoreNoticeContainer}>
          To cancel subscription, please review your subscriptions in the Google
          Play Store.
        </div>
      );
    } else {
      return (
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            id="cancel_button"
            color="primary"
            type="submit"
            onClick={(e) => parent.setState({ dialogOpen: true })}
            disabled={parent.state.loading}
            style={{ width: "100%", color: "#fff", maxWidth: 290 }}
          >
            Cancel Subscription
          </Button>
        </div>
      );
    }
  }
};

const _RenderError = (parent, classes) => {
  if (parent.state.plError) {
    return (
      <div style={{ color: "red", marginTop: 20 }}>{parent.state.plError}</div>
    );
  } else {
    return null;
  }
};

const _RenderPromocode = (parent, classes) => {
  return (
    <div className={classes.promoRow}>
      <TextField
        value={parent.state.promoCode}
        label="Promo code"
        name="promoCode"
        id="promoCode"
        onChange={(evt) =>
          parent.setState({ plError: null, promoCode: evt.target.value })
        }
        onFocus={() => {
          if (parent.state.plError) {
            parent.setState({ plError: null, promoCode: "" });
          }
        }}
        style={{ flex: 1 }}
        InputLabelProps={{ classes: { root: classes.labelRoot } }}
        type="text"
        inputProps={{
          autoComplete: "off",
          autoCorrect: "off",
          autoCapitalize: "off",
        }}
      />
      <Button
        className={classes.promoApplyButton}
        variant="outlined"
        color="primary"
        id="submit_button"
        type="submit"
        onClick={(e) => {
          applyPromo(parent.state.promoCode, parent);
        }}
        disabled={parent.state.loading}
      >
        Apply
      </Button>
    </div>
  );
};

const _RenderAdminButtons = (parent, classes) => {
  return (
    <div
      style={{
        display: "flex",
        margin: 15,
        marginTop: 5,
        flexDirection: "column",
      }}
    >
      <Button
        variant="contained"
        id="charge_button"
        color="primary"
        type="submit"
        onClick={(e) =>
          parent.setState({ dialogOpen: true, dialogType: "createCharge" })
        }
        disabled={parent.state.loading}
        style={{ width: "100%", color: "#fff" }}
      >
        Create Charge
      </Button>
    </div>
  );
};

const _PromoBox = (props) => {
  const { parent, classes } = props;

  // if (!parent.state.payment_method) {
  return (
    <div
      style={{
        width: "100%",
        alignSelf: "center",
        maxWidth: 450,
      }}
    >
      <div
        style={{
          display: "flex",
          margin: 15,
          marginTop: 5,
          flexDirection: "column",
        }}
      >
        {_RenderPromocode(parent, classes)}
        {_RenderError(parent, classes)}
        {parent.state.appliedPromo ? _RenderButton(parent, classes) : null}
      </div>
    </div>
  );
};

const _SubscriptionTierPurchase = ({ parent, classes, modalControl }) => {
  const monthData = {
    1: {
      cadence: "Monthly",
      monthlyRate: ["39", "99"],
      saleMonthlyRate: ["35", "99"],
      bgColor: "#ececec",
      totalCost: ["‎", "‎", "‎"], //* These are empty chars
      saleTotalCost: ["‎", "‎"], //* These are empty chars
      buttonOutline: "#002141",
      buttonColor: "#ececec",
      textColor: "#000000",
      buttonText: "Monthly Sign Up!",
      borderColor: "#ececec",
    },
    12: {
      cadence: "Yearly",
      monthlyRate: ["29", "99"],
      saleMonthlyRate: ["26", "99"],
      bgColor: "#ececec",
      totalCost: ["$359", "88", "Billed as one payment"],
      saleTotalCost: ["$323", "89"],
      buttonOutline: "#0089ff",
      buttonColor: "#0089ff",
      textColor: "#ffffff",
      buttonText: "Annual Sign Up!",
      borderColor: "#002141",
    },
  };

  const bonuses = [
    [
      `A custom weightlifting program that is personalized to your goals`,
      `AI enhanced programming that is responsive to your training`,
      // `Discord member perks! (Coming Soon)`,
      `WEIGHTLIFTING.ai Discord!`,
      `Access to 400+ exercise demos!`,
    ],
    [
      `3 months free!`,
      `30 min personal consultation w/ THE Max Aita`,
      `Free access to online Q&A seminars`,
      `Free access to WEIGHTLIFTING.ai training camps`,
    ],
  ];

  const hasSubscribed =
    parent.state.latest_charge || parent.state.payment_method;
  const webSignup = parent.state.payment_method?.toUpperCase() === "CARD";

  return (
    <div
      style={{
        width: "100%",
        alignSelf: "center",
        maxWidth: 1000,
      }}
    >
      {modalControl._renderDialog(saleData, hasSubscribed, webSignup)}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: "10px 0",
            fontSize: "xx-large",
            fontWeight: "bold",
            color: "black",
          }}
        >
          Subscription Tiers
        </div>
        {!!saleData["percent"] ? (
          <div
            style={{
              margin: 5,
              border: "2px black solid",
              fontSize: "xxx-large",
              fontWeight: "700",
              color: "white",
              backgroundColor: "#002141",
            }}
          >
            {saleData["reason"]} SALE!!! {saleData["percent"] * 100}% OFF!!!
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20px",
          }}
        >
          <div
            style={{
              width: "50%",
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
            }}
          >
            <div
              style={{
                border: "2px solid black",
                padding: "10px 25px",
                borderRadius: 25,
                position: "relative",
                top: "12px",
                backgroundColor: "#002141",
                color: "white",
              }}
            >
              RECOMMENDED
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            paddingBottom: 10,
          }}
        >
          {Object.entries(monthData).map((data, idx) => {
            return (
              <IndividualSubscriptionInfo
                parent={parent}
                modalControl={modalControl}
                months={data[0]}
                data={data[1]}
                hasSubscribed={hasSubscribed}
                sale={saleData["percent"]}
                bonuses={{ ...bonuses }}
                key={idx}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const _RenderTermsAccept = (classes, parent) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "68%",
          minWidth: 650,
          maxWidth: 950,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "#bcbcbc",
          margin: 5,
          fontSize: "small",
        }}
      >
        <div
          style={{
            color: "grey",
            fontWeight: 400,
          }}
        >
          BY SIGNING UP FOR THE APP, I UNDERSTAND THAT I AM ACCEPTING THE
          FOLLOWING{" "}
          <Link
            href="/terms"
            underline="always"
            target="_blank"
            color="#002141"
            sx={{
              fontWeight: 500,
              color: "grey",
              textDecoration: "underline",
              textDecorationColor: "grey",
            }}
          >
            TERMS AND CONDITIONS
          </Link>{" "}
          AND{" "}
          <Link
            href="/privacy"
            underline="always"
            target="_blank"
            color="#002141"
            sx={{
              fontWeight: 500,
              color: "grey",
              textDecoration: "underline",
              textDecorationColor: "grey",
            }}
          >
            PRIVACY POLICY
          </Link>
          . <br />
          SUBSCRIPTION RENEWS AUTOMATICALLY AT THE MONTHLY RATE.
        </div>
      </div>
    </div>
  );
};

const _AdminButtons = (props) => {
  var { parent, classes } = props;

  if (parent.state.admin) {
    return (
      <div
        style={{
          width: "100%",
          alignSelf: "center",
          maxWidth: 400,
        }}
      >
        {_RenderAdminButtons(parent, classes)}
      </div>
    );
  } else return null;
};

export const PromoBox = withStyles(styles)(_PromoBox);
export const AdminButtons = withStyles(styles)(_AdminButtons);
export const SubscriptionTierPurchase = withStyles(styles)(
  _SubscriptionTierPurchase
);
export const RenderTermsAccept = withStyles(styles)(_RenderTermsAccept);
export const RenderButton = withStyles(styles)(_RenderButton);
