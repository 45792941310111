import mixpanel from "mixpanel-browser";
import axios from "axios";
import { Auth } from "aws-amplify";
import { updateUser } from "../../state/api";
mixpanel.init("fb6d0cf9cf3b6d33c7c68d30106861a7");

const paymentsUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/payments`;
const adminURL = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin`;
const usersURL = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/users`;

const amountToPay = {
  12: 35988,
  1: 3999,
};

const amountToPaySale = {
  12: 32389,
  1: 3599,
};

export const refundCharge = async (e, parent, handleValidationError) => {
  console.log("refund charge with state:", parent.state);
  e.preventDefault();
  parent.setState({ loading: true, errors: [] });

  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  const config = {
    headers: { "Content-Type": "application/json", Authorization: authToken },
    params: { post: "refund_charge", source: "web", respond: true },
  };

  var errors = [];
  let charge = parent.state.chargeToRefund;
  var { payment_date } = charge;
  const regex = new RegExp("[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}");
  if (!payment_date.match(regex)) {
    errors.push("Date created format must be YYYY-MM-DD");
  }

  let data = JSON.stringify(charge);

  if (errors.length > 0) {
    handleValidationError(errors);
  } else {
    return axios
      .post(adminURL, data, config)
      .then((data) => {
        window.location.reload(false);
      })
      .catch((error) => console.log("error:", error));
  }
};

export const editCharge = async (e, parent, handleValidationError, charge) => {
  console.log("submit profile with state:", parent.state);
  e.preventDefault();
  parent.setState({ loading: true, errors: [] });

  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  const config = {
    headers: { "Content-Type": "application/json", Authorization: authToken },
    params: { post: "edit_charge", source: "web", respond: true },
  };

  var errors = [];
  var { payment_date } = charge;
  const regex = new RegExp("[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}");
  if (!payment_date.match(regex)) {
    errors.push("Date created format must be YYYY-MM-DD");
  }

  let data = JSON.stringify(charge);

  if (errors.length > 0) {
    handleValidationError(errors);
  } else {
    return axios
      .post(adminURL, data, config)
      .then((data) => {
        window.location.reload(false);
      })
      .catch((error) => console.log("error:", error));
  }
};

export const submitNewCharge = async (e, parent, handleValidationError) => {
  console.log("submit profile with state:", parent.state);
  e.preventDefault();
  parent.setState({ loading: true, errors: [] });

  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  const config = {
    headers: { "Content-Type": "application/json", Authorization: authToken },
    params: { post: "new_charge", source: "web", respond: true },
  };

  /////

  var errors = [];
  var { payment_date, amount, cognito_id } = parent.state;
  const regex = new RegExp("[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}");
  if (!payment_date.match(regex)) {
    errors.push("Date created format must be YYYY-MM-DD");
  }

  let data = { payment_date, amount, cognito_id };
  data = JSON.stringify(data);

  if (errors.length > 0) {
    handleValidationError(errors);
  } else {
    return axios
      .post(adminURL, data, config)
      .then((data) => {
        let charges = parent.state.charges;
        charges = [data.data].concat(charges);
        parent.setState({ loading: false, charges, dialogOpen: false });
      })
      .catch((error) => console.log("error:", error));
  }
};

export const handleSubmit = (e, parent, userData, handleValidationError) => {
  mixpanel.track("submit profile");
  e.preventDefault();
  parent.setState({ loading: true, errors: [] });

  var maxs = parent.state.maxs;

  for (const max in parent.state.maxs_copy) {
    const move = parent.state.comp_styles[max];
    maxs[move] = parent.state.maxs_copy[max];
  }

  const q = {
    cognito_id: parent.state.cognito_id,
    maxs,
    height: parent.state.height,
    bodyweight: parent.state.bodyweight,
    birthday: `${parent.state.birth_year}-${String(
      parent.state.birth_month
    ).padStart(2, "0")}-${String(parent.state.birth_day).padStart(2, "0")}`,
    email: parent.state.email,
    male: parent.state.male,
    metric: parent.state.metric,
    comp_styles: parent.state.comp_styles,
  };

  if (parent.state.teamCode) {
    q.athlete_code = parent.state.teamCode;
  }

  var errors = [];
  for (const property in q) {
    if (q[property] === null || q[property] === "") {
      errors.push(property);
    }

    if (property === "maxs") {
      console.log(q[property]);
      if (parent.state.program === "powerlifting") {
        for (const lift in q.comp_styles) {
          const val = q[property][q.comp_styles[lift]];
          console.log(val);
          if (
            val.max === 0 ||
            val.max === null ||
            val.max === "" ||
            val.max === "0"
          ) {
            errors.push(lift);
          }
        }
      } else {
        ["snatch", "clean", "jerk", "back_squat"].map((lift) => {
          console.log("lift:", lift);
          let lift_record = lift === "jerk" ? q.comp_styles.jerk : lift;
          if ([0, null, "", "0"].includes(q.maxs[lift_record].max)) {
            errors.push(lift);
          }
        });
      }
    }
  }

  if (errors.length > 0) {
    handleValidationError(errors);
  } else {
    parent.props
      .updateUserData(q)
      .then((m) => {
        parent.setState({ loading: false });
        window.location.href = window.location.href;
      })
      .catch((e) => {
        console.log(e.response);
        parent.setState({ loading: false, plError: e.response.data.error });
      });
  }
};

export const getStripeLink = async (parent, months, sale, hasSubscribed) => {
  console.log(
    "getting stripe link in profile api, parent state:",
    parent.state
  );

  const subRateCodes = {
    1: {
      new: "1monthsignup",
      old: "1monthrenewal",
    },
    12: {
      new: "12monthsignup",
      old: "12monthrenewal",
    },
  };

  const isNewCustomer = hasSubscribed ? "old" : "new";

  parent.setState({ plError: null, loading: true });

  const config = { headers: { "Content-Type": "application/json" } };
  const amount = !!sale
    ? amountToPaySale[months] || amountToPaySale[1]
    : amountToPay[months] || amountToPay[1];

  const q = {
    cognito_id: parent.state.cognito_id,
    email: parent.state.email,
    program: parent.state.program,
    url: window.location.href,
    amount: amount,
    payment_method: parent.state.payTabToDisplay,
    version: "2.0.0",
    source: "web",
  };

  var errors = [];

  if (months === 0) {
    const promoCode = parent.state.promoCode.trim();
    if (promoCode.length > 0) {
      q.promo_code = promoCode;
    }
  }
  if (months > 0) {
    if (Object.keys(subRateCodes).includes(months.toString())) {
      const promoCode = subRateCodes[months][isNewCustomer];
      q.promo_code = promoCode;
    }
  }

  const data = JSON.stringify(q);
  console.log("payload", data);

  mixpanel.track(`requesting stripe link`);

  if (errors.length === 0) {
    return axios
      .put(paymentsUrl, data, config)
      .then((data) => {
        console.log("got link ", data.data);
        mixpanel.track(`redirecting to stripe payment page`);
        window.location.href = data.data;
      })
      .catch((error) => {
        mixpanel.track(`stripe link error!`);
        parent.setState({
          plError: JSON.parse(error.response.data).message,
          loading: false,
        });
      });
  } else {
    parent.handleValidationError(errors);
    console.log("handling error state:", parent.state);
  }
};

export const postXMonthPurchase = async (parent, months, sale) => {
  console.log(
    "posting bulk purchase in profile api, parent state:",
    parent.state
  );

  parent.setState({ plError: "", loading: true });

  const config = { headers: { "Content-Type": "application/json" } };

  const amount = !!sale
    ? amountToPaySale[months] || amountToPaySale[1]
    : amountToPay[months] || amountToPay[1];

  // console.log("amount to pay", amount);

  const q = {
    cognito_id: parent.state.cognito_id,
    email: parent.state.email,
    program: parent.state.program,
    url: window.location.href,
    sui: parent.state.latest_sui,
    amount: amount,
    onboard: false,
    type: "bulk_purchase",
    version: "2.0.0",
    source: "web",
  };

  console.log(JSON.stringify(q, null, 2));
  const data = JSON.stringify(q);

  mixpanel.track(`making bulk purchase of ${months} months`);

  return axios
    .post(paymentsUrl, data, config)
    .then((data) => {
      console.log("got link ", data.data);
      mixpanel.track(`redirecting to stripe payment page`);
      window.location.href = data.data;
    })
    .catch((error) => {
      console.log("error in bulkPurchase:", error.response);
      mixpanel.track(`bulk purchase error!`);
      parent.setState({
        plError: error.response.data.message,
        loading: false,
        dialogOpen: false,
      });
    });
};

export const cancelSubscription = (parent) => {
  console.log("cancelling subscription");

  parent.setState({ pageLoading: true, dialogOpen: false });

  const config = { headers: { "Content-Type": "application/json" } };
  const cognito_id = parent.state.cognito_id;

  const url = `${paymentsUrl}/${cognito_id}?source=web&type=cancel`;

  return axios
    .delete(url, config)
    .then((data) => {
      console.log("from delete:", data);
      parent.setState({
        setup_intents: [],
        payment_method: null,
        pageLoading: false,
      });
    })
    .catch((error) => console.log("error:", error));
};

export const deleteUser = async (parent) => {
  console.log("deleting user");

  parent.setState({ deleteLoading: true });

  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  const config = {
    headers: { "Content-Type": "application/json", Authorization: authToken },
  };

  const url = `${usersURL}/${parent.state.cognito_id}?source=web&type=cancel`;

  return axios
    .delete(url, config)
    .then((data) => console.log("from user delete:", data))
    .catch((error) => parent.setState({ deleteLoading: false }));
};

export const deleteTeam = async (parent) => {
  console.log("deleting team");

  if ("setState" in parent) {
    parent.setState({ deleteLoading: true });
  }

  await updateUser({ cognito_id: parent.state.cognito_id, team: "" }).then(
    (data) => {
      window.location.href = window.location.href;
    }
  );
};
